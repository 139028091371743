import styled from "styled-components"

export const HeroContainer = styled.div`
font-family: Roboto;
position: relative;
background-color: #000000;
min-height: 500px;
width: 100%;
background-size: cover;
background-repeat: no-repeat;
background-position: center;
.background-image {
  position: absolute;
  width: 100vw;
  height: 100%;
}
@media (max-width: 992px) {
  background-position: right;
}
@media (max-width: 768px) {
  min-height: 432px;
}
${({ component }) => 
component.type.includes('tall') && `
  min-height: 900px;
  background-position: right;
  @media (max-width: 992px) {
    min-height: 500px;
  }
  @media (max-width: 768px) {
    min-height: 432px;
  }
`}
`
export const Container = styled.div`
position: relative;
height: 500px;
min-height: 100%;
margin:auto;
max-width: 1170px;
display: flex;
${({ type }) => 
type.includes('tall') && `
  height: 900px;
  @media (max-width: 992px) {
    height: 500px;
  }
  @media (max-width: 768px) {
    height: 432px;
  }
`}
@media (max-width: 992px) {
  max-width: 770px;
}
@media (max-width: 768px) {
  max-width: 506px;
  height: 432px;
}
`

export const TextContent = styled.div`
text-align: left;
position: relative;
display: flex;
flex-direction: column;
padding: 0 15px;
z-index: 100;
max-width: 570px;
width: 100%;
justify-content: center;
@media (max-width: 992px) {
  max-width: 370px;
}
@media (max-width: 768px) {
  max-width: 100%;
}
`

export const Gradient = styled.div`
position: absolute;
top: 50%;
left: 0;
width: 100%;
height: 100%;
transform: translateY(-50%);
background: linear-gradient(90deg, #101828 35.88%, rgba(16, 24, 40, 0.4) 98.71%);
opacity: 0.7;  
${({ type }) =>
  type.includes("blue") &&
  `
  background: #0000C0;
  opacity: 0.8;  
`}
${({ type }) => 
type.includes('split') && 
`
  width: 55%;
  @media (max-width: 992px) {
    width: 60%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`}
`

export const CTAContainer = styled.div`
width: 100%;
margin-top: 30px;
button {
  margin-right: 15px;
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 15px;
  }
}
@media (max-width: 768px) {
  flex-direction: column;
  margin-top: 15px;
}
`