import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Heading, TextMono, Text, Button } from "@atoms"
import {
  HeroContainer,
  Container,
  TextContent,
  Gradient,
  CTAContainer,
} from "./styles/HeroSplitFullWidth.styled"
import OptimizedImage from "../../utils/optimizedImage"
import Breadcrumbs from "./components/Breadcrumbs"
import { BreadcrumbContainer } from "./styles/Breadcrumbs.styled"

const HeroSplitFullWidth = ({ component, location, seoBreadcrumbs }) => {
  const {
    type,
    callsToAction,
    subheading,
    kicker,
    heading,
    headingSize,
    breadcrumbs,
    breadcrumbTitle,
  } = component

  return (
    <HeroContainer
      component={component}
      data-comp={HeroSplitFullWidth.displayName}
    >
      <OptimizedImage
        image={component?.featuredImage?.gatsbyImageData}
        src={component?.featuredImage?.file?.url}
        alt=""
        className="background-image"
        loading="eager"
      />
      {breadcrumbs && (
        <BreadcrumbContainer>
          <Breadcrumbs
            background={"dark"}
            location={location}
            seoBreadcrumbs={seoBreadcrumbs}
            breadcrumbTitle={breadcrumbTitle}
          />
        </BreadcrumbContainer>
      )}
      <Container type={type}>
        <TextContent>
          {kicker && (
            <TextMono style={{ color: "white", paddingBottom: "12px" }}>
              {kicker}
            </TextMono>
          )}
          {heading && (
            <Heading
              headingSize={headingSize}
              style={{ color: "white", paddingBottom: "16px" }}
            >
              {heading}
            </Heading>
          )}
          {subheading?.raw && (
            <Text pb={6} headingSize={headingSize} style={{ color: "white" }}>
              {documentToReactComponents(
                JSON.parse(component?.subheading?.raw)
              )}
            </Text>
          )}
          <CTAContainer style={{ display: "flex" }}>
            {callsToAction &&
              component?.callsToAction.map((cta, index) => {
                return (
                  <div className="button-wrapper">
                    <Button
                      ctaLink={cta?.link}
                      ctaVariant={index === 0 ? "secondary" : cta?.variant}
                    >
                      {cta?.label}
                    </Button>
                  </div>
                )
              })}
          </CTAContainer>
        </TextContent>
      </Container>
      <Gradient type={type} />
    </HeroContainer>
  )
}

export default HeroSplitFullWidth
HeroSplitFullWidth.displayName = "HeroSplitFullWidth"
